<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="row mt-5">
        <div class="col-12 col-sm-6 fz-20 mt-top">
          <span class="pe-auto" @click="$router.go(-1)"
            ><i class="fas fa-chevron-left"></i> {{ $t("back") }}</span
          >
        </div>
        <div class="col-12 col-sm-6">
          <div class="lang-switcher-outer">
            <div class="lang-switcher-holder mt-0">
              <LocaleSwitcher />
            </div>
          </div>
        </div>
      </div>

      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">
              {{ $t('sidebar.RevenueUpdates') }} | {{ SubAgentName }}
              <i class="fas fa-chevron-right"></i> {{ ShopName }}
            </div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-12 mb-3">
                <div class="row mb-5">
                  <div class="col-12 col-sm-12">
                    <label class="form-label">{{ $t("label.Search") }}:</label>
                    <input
                      type="text"
                      class="form-control agent-text top-search"
                      v-model="keyword"
                      @keyup="searchData"
                    />
                  </div>
                </div>
                <table class="table table-striped table-hover" width="100%">
                  <thead>
                    <tr>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th>{{ $t("label.Address") }}</th>
                      <th class="text-right action-pr">{{ $t("action") }}</th>
                    </tr>
                  </thead>
                  <tbody v-if="datas.length">
                    <tr :key="index" v-for="(item, index) in datas">
                      <td>{{ item.Name }}</td>
                      <td>{{ item.address }}</td>

                      <td class="text-right">
                        <button
                          type="button"
                          class="dtable-btn2"
                          @click="ViewCashier(item.AccountType, item.Name)"
                        >
                          {{ $t("label.ViewCashier") }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tr v-else>
                    <td colspan="6" class="text-center pt-3">
                      No records found.
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Pagination from "../components/Pagination";
import { getAgentListHierarchy } from "../api";
import router from "../router";
export default {
  name: "RevenueUpdatesView",
  components: {
    Pagination,
    LocaleSwitcher,
  },
  data() {
    return {
      keyword: "",
      SubAgentName: "",
      ShopName: "",
      datas: [
        {
          shop: "Betting Shop 1",
          address: "Central, Catedral, San Jose, Costa Rica",
        },
        {
          shop: "Betting Shop 2",
          address: "San Jose, Montes De Oca, San Pedro, Costa Rica",
        },
        {
          shop: "Betting Shop 3",
          address: "Central, Catedral, San Jose, Costa Rica",
        },
        {
          shop: "Betting Shop 4",
          address: "San Jose, Montes De Oca, San Pedro, Costa Rica",
        },
        {
          shop: "Betting Shop 5",
          address: "Central, Catedral, San Jose, Costa Rica",
        },
      ],
    };
  },
  methods: {
    async fetchAgentOptions($params) {
      let res = await getAgentListHierarchy($params);
      this.datas = res.data.map((item) => {
        return {
          AccountType: item.AccountId,
          Name: item.Name,
          CurrentAgentRS: item.RevShare,
          MasterAgentRS: item.ParentRevShare,
        };
      });
    },
    searchData() {
      if (this.keyword !== "") {
        this.fetchAgentOptions({
          intParentAccountId: JSON.parse(localStorage.getItem("SubAgentId")),
          intLimitPerPage: 10,
          strRef: this.keyword,
          intStart: 0,
        });
      } else {
        this.fetchAgentOptions({
          intParentAccountId: JSON.parse(localStorage.getItem("SubAgentId")),
          intLimitPerPage: 10,
          strRef: "",
          intStart: 0,
        });
      }
    },
    ViewCashier(id, name) {
      localStorage.removeItem("CashierId");
      localStorage.removeItem("CashierName");
      localStorage.setItem("CashierId", JSON.stringify(id));
      localStorage.setItem("CashierName", JSON.stringify(name));
      router.push({ name: "RevenueUpdateCashier" });
    },
  },
  mounted() {
    this.fetchAgentOptions({
      intParentAccountId: JSON.parse(localStorage.getItem("ShopId")),
      intLimitPerPage: 10,
      strRef: "",
      intStart: 0,
    });
    this.SubAgentName = JSON.parse(localStorage.getItem("SubAgentName"));
    this.ShopName = JSON.parse(localStorage.getItem("ShopName"));
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.table th,
.table td {
  vertical-align: middle;
}
.action-pr {
  padding-right: 3.5% !important;
}
</style>