<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("label.CollectionReports") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="BettingShopValue"
                      :options="BettingShops"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("date") }}:</label>
                    <input type="date" class="form-control agent-text" />
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("siteNames.COLLECTOR") }}:</label>
                    <Select2
                      v-model="CollectorValue"
                      :options="collectorOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("label.Status") }}:</label>
                    <Select2
                      v-model="StatusValue"
                      :options="Statuses"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12 mt-5 mb-5">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-gen active">
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-dl active">
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button type="button" class="rs-btn rs-print active">
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-12 mb-3">
                <table
                  class="table table-striped table-hover dtable-border"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>{{ $t("label.BettingShop") }}</th>
                      <th class="cr-tw">{{ $t("label.DateTime") }}</th>
                      <th>{{ $t("label.TransactionNo") }}</th>
                      <th>{{ $t("label.CashierName") }}</th>
                      <th>{{ $t("siteNames.COLLECTOR") }}</th>
                      <th class="text-right">{{ $t("label.PreviousBalance") }}</th>
                      <th class="text-right">{{ $t("label.TotalBets") }}</th>
                      <th class="text-right">{{ $t("payouts") }}</th>
                      <th class="text-right">{{ $t("label.Commissions") }}</th>
                      <th class="text-right">{{ $t("label.ForCollection") }}</th>
                      <th class="text-right">{{ $t("label.CollectedAmount") }}</th>
                      <th class="text-right">{{ $t("label.Status") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr :key="index" v-for="(item, index) in dataList">
                      <td>
                        {{ item.bettingShop }}
                      </td>
                      <td>{{ item.date }}</td>
                      <td>{{ item.transno }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.collector }}</td>
                      <td class="text-right">{{ item.prevBalance }}</td>
                      <td class="text-right">{{ item.totalBet }}</td>
                      <td class="text-right">{{ item.payouts }}</td>
                      <td class="text-right">{{ item.commission }}</td>
                      <td class="text-right">{{ item.collection }}</td>
                      <td class="text-right">{{ item.collectedAmount }}</td>
                      <td class="text-right">{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
import { getCollectorList, getBettingShopListbyCollector } from "../api"
export default {
  name: "CollectionReport",
  components: {
    Pagination,
    Select2,
    LocaleSwitcher,
  },
  data() {
    return {
      myValue: "",
      BettingShopValue: "",
      CollectorValue: "",
      StatusValue: "",
      auth: {},
      collectorOptions: [],
      bettingShopOptions: [],
      myOptions: ["op1", "op2", "op3"], // or [{id: key, text: value}, {id: key, text: value}]
      BettingShops: ["Betting Shop 1", "Betting Shop 2", "Betting Shop 3"],
      Statuses: ["Fully Paid", "Paid", "Unpaid", "Partial Paid"],
      filterByOption: [
        { id: 1, text: "Date" },
        { id: 2, text: "Event" },
      ],
      filterVal: 1,
      dataList: [
        {
          bettingShop: "Betting Shop 2",
          date: "11/02/2022 3:00:00 PM",
          transno: "12345678901",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "--",
          totalBet: "16,375.32",
          payouts: "10,500.00",
          commission: "491.26",
          collection: "5,384.06",
          collectedAmount: "5,000.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/03/2022 3:00:00 PM",
          transno: "12345678902",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "384.06",
          totalBet: "45,442.06",
          payouts: "43,000.00",
          commission: "1,363.26",
          collection: "1,078.80",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/04/2022 3:00:00 PM",
          transno: "12345678903",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "1,078.80",
          totalBet: "12,474.59",
          payouts: "10,000.00",
          commission: "374.24",
          collection: "2,100.35",
          collectedAmount: "2,100.35",
          status: "Fully Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/05/2022 3:00:00 PM",
          transno: "12345678905",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "--",
          totalBet: "26,635.78",
          payouts: "15,000.00",
          commission: "799.07",
          collection: "10,836.71",
          collectedAmount: "10,836.71",
          status: "Fully Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/06/2022 3:00:00 PM",
          transno: "12345678907",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "--",
          totalBet: "17.40",
          payouts: "20.00",
          commission: "0.52",
          collection: "3.12",
          collectedAmount: "--",
          status: "Unpaid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/07/2022 3:00:00 PM",
          transno: "12345678909",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "3.12",
          totalBet: "8,429.81",
          payouts: "5,000.00",
          commission: "252.89",
          collection: "3,176.92",
          collectedAmount: "3,000.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/08/2022 3:00:00 PM",
          transno: "12345678910",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "176.92",
          totalBet: "12,843.23",
          payouts: "10,000.00",
          commission: "385.30",
          collection: "2,457.93",
          collectedAmount: "2,000.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/09/2022 3:00:00 PM",
          transno: "12345678914",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "457.93",
          totalBet: "9,558.90",
          payouts: "8,500.00",
          commission: "286.77",
          collection: "772.13",
          collectedAmount: "500.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/10/2022 3:00:00 PM",
          transno: "12345678915",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "272.13",
          totalBet: "7,971.22",
          payouts: "5,000.00",
          commission: "239.14",
          collection: "2,732.08",
          collectedAmount: "2,000.00",
          status: "Partial Paid",
        },
        {
          bettingShop: "Betting Shop 2",
          date: "11/11/2022 3:00:00 PM",
          transno: "12345678918",
          name: "Cashier2",
          collector: "Collector1",
          prevBalance: "732.08",
          totalBet: "1,787.80",
          payouts: "1,000.00",
          commission: "53.63",
          collection: "734.17",
          collectedAmount: "734.17",
          status: "Fully Paid",
        },        
      ],
    };
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({ id, text });
    },
    async fetchBettingShopOptions() {
      let res = await getBettingShopListbyCollector(this.auth.AccountId);
      this.bettingShopOptions = res.data.map( (d) => {
        return {id: d.AccountId, text: d.Name}
      })
    },
    async fetchCollectorOptions() {
      let res = await getCollectorList();
      this.collectorOptions = res.data.map( (d) => {
        return {id: d.AccountId, text: d.Name}
      })
    },
  },
  mounted() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.fetchCollectorOptions();
    this.fetchBettingShopOptions();
  },
};
</script>

<style>
@import "bootstrap";
@import "datatables.net-bs5";
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
.cr-tw {
  width: 120px;
}
</style>