<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">
              {{ $t("sidebar.PlaceBet") }}

              <span
                v-if="fightStatus.StatusName"
                class="float-end rounded-stat"
                :class="{
                  'bet-open':
                    fightStatus.StatusName.toLowerCase() === 'bet open',
                  'last-call':
                    fightStatus.StatusName.toLowerCase() === 'last call',
                  'on-stand-by':
                    fightStatus.StatusName.toLowerCase() === 'on standby',
                  'fight-on-going':
                    fightStatus.StatusName.toLowerCase() === 'fight on-going',
                  'fight-cancelled':
                    fightStatus.StatusName.toLowerCase() === 'fight cancelled',
                  'red-wins':
                    fightStatus.StatusName.toLowerCase() === 'red wins',
                  'blue-wins':
                    fightStatus.StatusName.toLowerCase() === 'blue wins',
                  'stat-completed':
                    fightStatus.StatusName.toLowerCase() === 'completed',
                  'fight-cancelled':
                    fightStatus.StatusName.toLowerCase() === 'cancelled',
                }"
              >
                {{
                  fightStatus.StatusName.toLowerCase() === "cancelled"
                    ? $t("fight cancelled")
                    : $t(fightStatus.StatusName.toLowerCase())
                }}
              </span>
            </div>
            <ul class="nav nav-tabs">
              <li
                v-for="paymentMethod in paymentMethods"
                v-bind:key="paymentMethod.id"
                class="nav-item"
              >
                <a class="nav-link active" href="#cash" data-bs-toggle="tab">{{
                  $t(paymentMethod.name)
                }}</a>
              </li>

              <li class="nav-item" @click="getMOP(1)">
                <a class="nav-link active" href="#cash" data-bs-toggle="tab">{{
                  $t("cash")
                }}</a>
              </li>
              <li class="nav-item" @click="getMOP(2)">
                <a class="nav-link" href="#debit" data-bs-toggle="tab">{{
                  $t("debitcard")
                }}</a>
              </li>
              <li class="nav-item" @click="getMOP(3)">
                <a
                  class="nav-link nav-last-link"
                  href="#credit"
                  data-bs-toggle="tab"
                  >{{ $t("creditcard") }}</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content">
            <BetAmount v-model:amount="amount" @onClick="removeSelected" />
            <div class="tab-pane fade show active" id="cash">
              <Cash
                :clear="clear"
                @selectedAmount="onChangeValue($event)"
                ref="trigger"
                required="true"
              />
              <NumPad @selectedNumAmount="onNumChangeValue($event)" />
              <div class="float-keeper"></div>
            </div>
            <div class="tab-pane fade" id="debit">
              <Card
                :time="time"
                :date="date"
                :cashierNumber="cashierNumber"
                v-model:referenceNumber="referenceNumber"
                v-model:cardHolderName="cardHolderName"
                v-model:cardNumber="cardNumber"
                v-model:fightNumber="fightNumber"
                v-model:cardType="cardType"
              />
            </div>
            <div class="tab-pane fade" id="credit">
              <Card
                :time="time"
                :date="date"
                :cashierNumber="cashierNumber"
                v-model:referenceNumber="referenceNumber"
                v-model:cardHolderName="cardHolderName"
                v-model:cardNumber="cardNumber"
                v-model:fightNumber="fightNumber"
                v-model:cardType="cardType"
              />
            </div>
            <BetFooter
              :disabled="disabled"
              :bettingOption="bettingOption"
              :showLoadingIcon="showLoadingIcon"
              :status="status"
              @clickModal="callModal($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav />
    </div>
  </div>
  <Modal
    :amount="Number(amount)"
    :bettingOption="bettingOption"
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    @clickSubmit="SubmitBet"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
//fakepush
import BetAmount from "../components/tabs/BetAmount";
import Cash from "../components/tabs/Cash";
import Card from "../components/tabs/Card";
import NumPad from "../components/tabs/NumPad";
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal";
// import router from "../router"; // Commenting for Revised Print
import { useActionStore } from "@/store/action.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useStats } from "@/store/stats.store";
import { useAuthStore } from "@/store/user.store";
import { useStructure, fightEvents } from "@/store/global";
import { useLicenseStatus } from "@/store/license.store";

export default {
  name: "PlaceBet",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: {
    BetAmount,
    Cash,
    Card,
    BetFooter,
    RightNav,
    Modal,
    NumPad,
  },
  data() {
    return {
      accountUserId: 1,
      mop: 1,
      betType: 1,
      credit: 100000,

      amount: "",
      cardType: "1",
      referenceNumber: "",
      cardHolderName: "",
      cardNumber: "",
      fightNumber: null,
      cashierNumber: "4",
      dateTime: null,
      date: null,
      time: null,
      clear: false,
      isShowModal: false,
      status: "PlaceBet",
      status2: false,
      bettingOption: null,
      showLoadingIcon: false,
      numpadValue: "",
      count: 0,
      successMessage: "",
      errorMessage: "",
      threshold: null,
      paymentMethods: [],
      structure: {},
      disabled: false,
      fightStatus: "",
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    onChangeValue(val) {
      this.removeSelected();
      this.amount = val.toString();
      this.clear = false;
    },
    onNumChangeValue(val) {
      if (!isNaN(val) || val === ".") {
        if (val === "." && this.amount.includes(".")) return;
        this.numpadValue = this.amount + `${val}`;
        this.amount = this.numpadValue;
      } else {
        if (val === "clear") {
          this.amount = this.amount.slice(0, -1);
        }
      }
      this.$refs.trigger.resetSelected();
    },
    removeSelected() {
      this.amount = "";
      this.numpadValue = "";
      this.count = 0;
      this.clear = true;
      this.$refs.trigger.resetSelected();
      this.strNumpadValue = "";
    },

    close() {
      this.isShowModal = false;
      this.status2 = false;
      this.numpadValue = "";
      this.count = 0;
      this.strNumpadValue = "";
      this.$refs.trigger.resetSelected();
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    callModal(val) {
      if (Number(this.amount) > 0) {
        this.bettingOption = val;
        this.isShowModal = true;
        this.status2 = false;
        this.betType = val;
      } else {
        this.errorMessage = this.$t("invalid-amount");
        this.$refs.modalError.open();
        //setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
    getDateTime() {
      const today = new Date();
      const date =
        ("0" + (today.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + today.getDate()).slice(-2) +
        "/" +
        today.getFullYear();
      const time =
        String(today.getHours()).padStart(2, "0") +
        ":" +
        String(today.getMinutes()).padStart(2, "0") +
        ":" +
        String(today.getSeconds()).padStart(2, "0");
      const dateTime = date + " " + time;
      this.dateTime = dateTime;

      this.date = date;
    },
    formatAMPM() {
      const date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime =
        String(hours).padStart(2, "0") +
        ":" +
        String(minutes).padStart(2, "0") +
        " " +
        ampm;
      this.time = strTime;
    },
    getMOP(val) {
      this.mop = val;
    },
    SubmitBet() {
      const license = useLicenseStatus();
      license.getLicense();

      this.showLoadingIcon = true;
      let data = {};
      if (this.mop === 1) {
        data = {
          intPaymentMethod: this.mop,
          strCardType: "",
          strCardHolderName: "",
          strCardNumber: "",
          intBetOptionId: this.betType,
          dblAmount: Number(this.amount).toFixed(2),
        };
      }
      if (this.mop === 2) {
        data = {
          intPaymentMethod: this.mop,
          strReferenceNo: this.referenceNumber,
          strCardType: this.cardType,
          strCardHolderName: this.cardHolderName,
          strCardNumber: this.cardNumber,
          intBetOptionId: this.betType,
          dblAmount: Number(this.amount).toFixed(2),
        };
      }
      if (this.mop === 3) {
        data = {
          intPaymentMethod: this.mop,
          strReferenceNo: this.referenceNumber,
          strCardType: this.cardType,
          strCardHolderName: this.cardHolderName,
          strCardNumber: this.cardNumber,
          intBetOptionId: this.betType,
          dblAmount: Number(this.amount).toFixed(2),
        };
      }
      const action = useActionStore();
      action.submitPlaceBet(data);

      action.response.then((result) => {
        this.disabled = true;

        if (result.status === "success") {
          this.successMessage = this.$t("place-bet-submit-successfully");
          this.$refs.trigger.resetSelected();
          const stats = useStats();
          stats.refresh();
          this.removeSelected();
          this.setReciept(result.data);
          this.disabled = false;
          // Revised Print
          const awew = useAuthStore();
          let $betId = result.data.barcode.split('-')[0];
          let printEndpoint = `${awew.printBaseUrl}/cashier/print/receipt/bet/${$betId}/${awew.getToken}`;
          window.open(printEndpoint);
          // router.push({ name: "ReCeipt" }); // OLD PRINT
        } else {
          if (result.data.ErrorCode == 10000070) {
            localStorage.removeItem("auth");
            window.location.reload();
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }
            this.$refs.modalError.open();
            setTimeout(() => {
              this.$refs.modalError.close();
              this.disabled = false;
            }, 5000);
          }
        }
      });

      this.resetForm();
      this.$refs.trigger.resetSelected();
      this.isShowModal = false;
    },
    setReciept(data) {
      localStorage.removeItem("module");
      localStorage.removeItem("TransactionReceipt");
      localStorage.removeItem("transactionno");
      localStorage.removeItem("datetime");
      localStorage.removeItem("fightno");
      localStorage.removeItem("betoption");
      localStorage.removeItem("betamount");
      localStorage.removeItem("payout");
      localStorage.removeItem("odds");
      localStorage.removeItem("cashierno");
      localStorage.removeItem("statuss");
      sessionStorage.removeItem("barcode");

      localStorage.removeItem("availablecredits");
      localStorage.removeItem("cashin");
      localStorage.removeItem("cashonhand");
      localStorage.removeItem("payouts");
      localStorage.removeItem("approvedcredit");
      localStorage.removeItem("cashiername");
      localStorage.removeItem("timestamp");

      localStorage.setItem("module", JSON.stringify("PlaceBet"));
      localStorage.setItem("transactionno", JSON.stringify(data.transactionNo));
      localStorage.setItem("datetime", JSON.stringify(data.dateTime));
      localStorage.setItem("fightno", JSON.stringify(data.fightNo));
      localStorage.setItem("betoption", JSON.stringify(data.betOption));
      localStorage.setItem(
        "betamount",
        JSON.stringify(
          Number(data.Amount)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      //localStorage.setItem("odds", JSON.stringify(data.odds));
      localStorage.setItem("cashierno", JSON.stringify(data.CashierNo));
      localStorage.setItem("barcode", JSON.stringify(data.barcode));
    },
    resetForm() {
      // this.amount = "";
      this.betType = 1;
      // this.referenceNumber = "";
      // this.cardHolderName = "";
      // this.cardNumber = "";
      this.fightNumber = null;
      // this.cardType = "1";
      this.showLoadingIcon = false;
      this.disabled = false;
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          // console.log(result);
          if (result.status === "success") {
            this.successMessage = this.$t(
              "your-request-submitted-successfully"
            );
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("your-request-failed");
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = this.$t("please-make-sure-to-enter-amount");
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
  mounted() {
    const stats = useStats();
    stats.refresh();
    this.structure = useStructure();
    this.structure.fetch();
    this.formatAMPM();
    this.getDateTime();
    const license = useLicenseStatus();
    license.getLicense();

    this.fightStatus = fightEvents();
  },
};
</script>
