import { mande, defaults } from 'mande';
import { getActiveSite, isActiveSite } from '@/helpers/activesite';
import { getMachineId } from './store/global';

const operatorName = window.location.hostname
  .replace(new RegExp(['collector.', 'agent.'].join('\\b|\\b'), 'gi'), '')
  .trim()
  .split('.')[0]
  .toUpperCase()
  .replace('-', '');

const getMande = function (endpoint) {
  var API_ENDPOINT = process.env.VUE_APP_API_URL;

  if (window.location.hostname.includes('ibk.bet')) {
    API_ENDPOINT =
      'https://api.' +
      window.location.hostname
        .replace(new RegExp(['collector.', 'agent.'].join('\\b|\\b'), 'gi'), '')
        .trim();
  } else {
    API_ENDPOINT =
      'https://exuid1bho3.execute-api.ap-southeast-1.amazonaws.com';
  }

  const api = mande(API_ENDPOINT + endpoint);
  return api;
};

const getAPIKey = function () {
  let apiKey = process.env.VUE_APP_API_LICENSE_KEY;
  if (process.env['VUE_APP_API_LICENSE_KEY_' + operatorName]) {
    apiKey = process.env['VUE_APP_API_LICENSE_KEY_' + operatorName];
  }
  return apiKey;
};

export function validateResponse(response) {
  return response;
}

const sysAdmin = getMande('/sysAdmin');

const cashierbetting = getMande('/cashierbetting');
const operator = getMande('/operator');
const requestThresholdURI = getMande('/requestThreshold');
const betConsole = getMande('/betConsole');

function getActiveSiteApi() {
  return isActiveSite('CASHIER')
    ? sysAdmin
    : getMande('/' + getActiveSite().toLowerCase());
}

// Dynamic site base api object
const activeSiteApi = getActiveSiteApi();

defaults.headers['apikey'] = getAPIKey();
defaults.headers['x-access-token'] =
  localStorage.getItem('auth') != null
    ? JSON.parse(localStorage.getItem('auth')).Token
    : '';

export function authenticate(username, password) {
  var data = {
    strUsername: username,
    strPassword: password,
    ClientUniqueId: getMachineId(),
  };
  var accountType = {
    intAccountTypeId: process.env.VUE_APP_API_ACCOUNT_TYPE_ID,
  };

  return activeSiteApi.post('/login', {
    ...data,
    ...(isActiveSite('CASHIER') ? accountType : {}),
  });
}

export function getAccountInfo(username) {
  return activeSiteApi.get('/userAcctInfo/' + username);
}

export function resetPassword(email, logo) {
  return activeSiteApi.post('/requestResetPassword', {
    strEmail: email,
    intAccountTypeId: process.env.VUE_APP_API_ACCOUNT_TYPE_ID,
    strCallBackUrl: window.location.origin + '/reset-password/',
    strLang: localStorage.getItem('lang') || 'en',
    strLogoUri: logo,
  });
}

export function saveNewPassword(newPassword, token) {
  return activeSiteApi.put('/savePassword', {
    strNewPassword: newPassword,
    token: token,
    strLang: localStorage.getItem('lang') || 'en',
  });
}
export function logout(userId) {
  return activeSiteApi.put('/logout', { intUserId: userId });
}

export function checkLicenseRemainingDays() {
  return sysAdmin.get('/GetLicenseRemainingDays');
}

export function GetSummaryTransAmountByCashierId() {
  return cashierbetting.get('/GetSummaryTransAmountByCashierId');
}

export function placeBetSubmit(data) {
  return cashierbetting.post('/place-bet', data);
}

export function payOutGet(param) {
  return cashierbetting.get('/payout/bet-info' + param);
}

export function payOutSubmit(param) {
  return cashierbetting.post('/payout', param);
}

export function requestCreditSubmit(amount) {
  return cashierbetting.post('/PostCreditRequest', {
    dblAmount: amount,
  });
}

export function requestSummaryReports() {
  return cashierbetting.get('/GetCashierTransactionSummary');
}

export function getCancelBet(data) {
  data.intBetId =
    data.intBetId == undefined || data.intBetId == '' ? 0 : data.intBetId;
  data.strPinCode =
    data.strPinCode == undefined || data.strPinCode == '' ? 0 : data.strPinCode;
  return cashierbetting.get(
    '/GetPlacedBetForCancellation?intBetId=' +
      data.intBetId +
      '&strPinCode=' +
      data.strPinCode
  );
}

export function cancelBet(data) {
  return cashierbetting.post('/PostCancellation', {
    intBetId: data.intBetId,
    strPinCode: data.strPinCode,
  });
}

export function transaction(data) {
  data.transactionType =
    data.transactionType == undefined || data.transactionType == ''
      ? 0
      : data.transactionType;
  data.intFightResult =
    data.intFightResult == undefined || data.intFightResult == ''
      ? 0
      : data.intFightResult;
  data.transactionNumber =
    data.transactionNumber == undefined || data.transactionNumber == ''
      ? 0
      : data.transactionNumber;
  data.fightno =
    data.fightno == undefined || data.fightno == '' ? 0 : data.fightno;
  data.paymentMethod =
    data.paymentMethod == undefined || data.paymentMethod == ''
      ? 0
      : data.paymentMethod;
  data.betOption =
    data.betOption == undefined || data.betOption == '' ? 0 : data.betOption;
  data.intStart =
    data.intStart == undefined || data.intStart == '' ? 0 : data.intStart;
  data.intLimitPerPage =
    data.intLimitPerPage == undefined || data.intLimitPerPage == ''
      ? 10
      : data.intLimitPerPage;
  return cashierbetting.get(
    '/GetCashierBetTransactionLogs?intStart=' +
      data.intStart +
      '&intLimitPerPage=' +
      data.intLimitPerPage +
      '&intTransactionId=' +
      data.transactionNumber +
      '&intFightNo=' +
      data.fightno +
      '&intTransactionTypeId=' +
      data.transactionType +
      '&intPaymentMethod=' +
      data.paymentMethod +
      '&intBetOption=' +
      data.betOption +
      '&intFightResult=' +
      data.intFightResult
  );
}

export function transactionReceipt(data) {
  return cashierbetting.get(
    '/GetPlacedBetInfoByTransactionId?intTransactionId=' + data
  );
}

export function getAmountForEndorsementDetails() {
  return cashierbetting.get('/AmountForEndorsementDetails');
}

export function getAmountForEndorsementDetailsCollector() {
  return cashierbetting.get('/AmountForEndorsementDetailsCollector');
}

export function PostEndorsedAmount($data) {
  return cashierbetting.post('/PostEndorsedAmount', $data);
}

export function requestThresholdSubmit(amount) {
  return requestThresholdURI.post({
    amount: amount,
  });
}

export function PaymentMethods() {
  return operator.get('/getPaymentMethod').then(validateResponse);
}

export function TransactionTypes() {
  return operator.get('/getTransactionTypeList').then(validateResponse);
}

export function validateToken(token) {
  var api = getActiveSite();
  return api.get(
    `/validatePasswordToken?token=${token}&accountTypeId=${process.env.VUE_APP_API_ACCOUNT_TYPE_ID}`
  );
}

export function getPusherCreds() {
  return betConsole.get('/getPusherCreds');
}

export function postAgent($data) {
  return activeSiteApi.post('/AccountCreate', $data);
}

export function getMaxRevShare($parentId) {
  return activeSiteApi.get(`/MaxRevShare?Parentid=${$parentId}`);
}

export function getAgentListHierarchy($data) {
  return activeSiteApi.get(
    `/AgentListHierarchy?intParentAccountId=${$data.intParentAccountId}&intLimitPerPage=${$data.intLimitPerPage}&strRef=${$data.strRef}&intStart=${$data.intStart}`
  );
}

export function getCollectorList() {
  return activeSiteApi.get('/CollectorList');
}

export function getCashierList() {
  return activeSiteApi.get('/CashierList');
}

export function postUpdateRevSingle($data) {
  return activeSiteApi.post('/RevShareUpdate', $data);
}

export function postUpdateRevBatch($data) {
  return activeSiteApi.post('/BatchRevShareUpdate', $data);
}

export function getAgentSummaryReport($data) {
  return activeSiteApi.get(
    `/SummaryReport?intLoginAgentAccountId=${$data.intLoginAgentAccountId}&intFilterBy=${$data.intFilterBy}&strDateFrom=${$data.strDateFrom}&strDateTo=${$data.strDateTo}&intEventId=${$data.intEventId}&intAccountTypeId=${$data.intAccountTypeId}&intAgentAccountId=${$data.intAgentAccountId}&intBettingShopId=${$data.intBettingShopId}&intLimitPerPage=${$data.intLimitPerPage}&intStart=${$data.intStart}`
  );
}

export function getAgentEventList() {
  return activeSiteApi.get(`/EventList`);
}

export function getAgentBettingshopList() {
  return activeSiteApi.get(`/BettingshopList`);
}

export function getAgentNameList() {
  return activeSiteApi.get(`/NameList`);
}

export function getAgentSummaryReportTotal($data) {
  return activeSiteApi.get(
    `/SummaryReportTotal?intLoginAgentAccountId=${$data.intLoginAgentAccountId}&intFilterBy=${$data.intFilterBy}&strDateFrom=${$data.strDateFrom}&strDateTo=${$data.strDateTo}&intEventId=${$data.intEventId}&intAccountTypeId=${$data.intAccountTypeId}&intAgentAccountId=${$data.intAgentAccountId}&intBettingShopId=${$data.intBettingShopId}`
  );
}

export function getBettingShopListbyCollector($collectorID) {
  return activeSiteApi.get(`/BettingShopListbyCollector?collectorid=${$collectorID}`);
}
