<template>
  <table class="table table-striped table-hover dtable-border" width="100%">
    <thead>
      <tr>
        <th>{{ $t("label.CollectorsName") }}</th>
        <th>{{ $t("label.DateTime") }}</th>
        <th>{{ $t("label.TransactionNo") }}</th>
        <th>{{ $t("label.PreviousBalance") }}</th>
        <th>{{ $t("label.TotalBets") }}</th>
        <th class="text-right">{{ $t("payouts") }}</th>
        <th class="text-right">{{ $t("label.Commissions") }}</th>
        <th class="text-center">{{ $t("label.ForCollection") }}</th>
        <th>{{ $t("label.CollectedAmount") }}</th>
        <th>{{ $t("label.Status") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in dataList">
        <td>{{ item.collectorsName }}</td>
        <td>{{ item.datetime }}</td>
        <td>{{ item.transactionNo }}</td>
        <td>{{ item.prevBalance }}</td>
        <td>{{ item.totalBets }}</td>
        <td class="text-right">{{ item.payouts }}</td>
        <td class="text-right">{{ item.commissions }}</td>
        <td class="text-center">{{ item.forCollection }}</td>
        <td>{{ item.collectedAmount }}</td>
        <td>{{ item.status }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ContentToPrint",
  props: ["dataList"],
};
</script>