<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-9 col-xl-9 center-holder-box">
      <div class="lang-switcher-outer">
        <div class="notif-holder">
          <div class="position-relative bell-holder" @click="showNotif()">
            <i class="fas fa-bell"></i>
            <span
              class="
                position-absolute
                top-0
                start-100
                translate-middle
                badge
                rounded-pill
                bg-danger
              "
            >
              1
            </span>
          </div>
          Saturday, September 17
        </div>
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("label.AssignedBettingShop") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div
                class="betting-shop-holder form-group"
                :key="index"
                v-for="(item, index) in dataList"
              >
                <div class="content">
                  <div class="col-12 items fw-800">{{ item.bettingShop }}</div>
                  <div class="col-12 mt-2 items fz-14">{{ $t("label.CollectingAmount") }}:</div>
                  <div class="row col-12 mt-2 items">
                    <div class="col-sm-8 fz-14">
                      {{ item.collectingAmount }}
                    </div>
                    <div class="col-sm-4">
                      <button
                        class="btn-shop-detail yellow-bg"
                        @click="showConfirm(1)"
                      >
                        <span>{{ $t("label.Details") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal :status="status" v-if="isShowModal" @close="close"></Modal>
  <Notification
    :notif="notif"
    v-if="isShowNotif"
    @close="close"
    @clickModal="clickNotif"
  ></Notification>
  <NotificationDetails
    :status="status"
    v-if="isShowDetails"
    @close="close"
  ></NotificationDetails>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Notification from "../components/modal/NotificationList";
import Modal from "../components/modal/AmountModal";
import NotificationDetails from "../components/modal/NotificationDetails";
export default {
  name: "AssignedBetttingShop",
  components: {
    Notification,
    Modal,
    NotificationDetails,
    LocaleSwitcher,
  },
  data() {
    return {
      isShowModal: false,
      isShowDetails: false,
      isShowNotif: false,
      status: "",
      notif: "",
      dataList: [
        {
          bettingShop: "Apuestotal, Branch 1",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "1,250,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 2",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "1,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 3",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "2,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 4",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "2,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 5",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "500,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 6",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "1,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 7",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "1,200,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 8",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "3,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 9",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "2,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 10",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "5,000,000.00",
        },
        {
          bettingShop: "Apuestotal, Branch 11",
          collector: "Paid",
          cashierName: "Paid",
          collectingAmount: "5,000,000.00",
        },
      ],
    };
  },
  methods: {
    close() {
      this.isShowModal = false;
      this.isShowNotif = false;
      this.isShowDetails = false;
      this.status = "";
      this.notif = "";
    },
    showConfirm() {
      this.isShowModal = true;
    },
    showNotif() {
      this.isShowNotif = true;
    },
    clickNotif() {
      this.notif = "";
      this.isShowModal = false;
      this.isShowNotif = false;
      this.isShowDetails = true;
    },
  },
};
</script>
