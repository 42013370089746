<template>
  <table class="table table-striped table-hover dtable-border" width="100%">
    <thead>
      <tr>
        <th>{{ $t("label.BettingShop") }}</th>
        <th class="cr-tw">{{ $t("label.DateTime") }}</th>
        <th>{{ $t("label.TransactionNo") }}</th>
        <th>{{ $t("label.CashierName") }}</th>
        <th class="text-right">
          {{ $t("label.PreviousBalance") }}
        </th>
        <th class="text-right">{{ $t("label.TotalBets") }}</th>
        <th class="text-right">{{ $t("payouts") }}</th>
        <th class="text-right">{{ $t("label.Commissions") }}</th>
        <th class="text-right">{{ $t("label.ForCollection") }}</th>
        <th class="text-right">
          {{ $t("label.CollectedAmount") }}
        </th>
        <th class="text-center">{{ $t("label.Status") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in dataList">
        <td>
          {{ item.bettingShop + " " + parseInt(index + 1) }}
        </td>
        <td>{{ item.date }}</td>
        <td>{{ item.transactionNo + parseInt(index + 1) }}</td>
        <td>
          {{ item.cashierName + " " + parseInt(index + 1) }}
        </td>
        <td class="text-right">{{ item.prevBalance }}</td>
        <td class="text-right">{{ item.totalBet }}</td>
        <td class="text-right">{{ item.payouts }}</td>
        <td class="text-right">{{ item.commission }}</td>
        <td class="text-right">{{ item.collection }}</td>
        <td class="text-right">{{ item.collectedAmount }}</td>
        <td class="text-center">{{ item.status }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ContentToDownloadCollector",
  props: ["dataList"],
};
</script>