import { ref } from 'vue';
import { defineStore } from 'pinia';
import { authenticate, logout, getAccountInfo } from '@/api';
import { defaults } from 'mande';
import { useAlertStore, realtimeEvents } from '@/store/global';
import router from '../router';
import {isActiveSite} from "@/helpers/activesite";

console.log(window.location.host,window.location.hostname);

export const useAuthStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'authStore',
  state: () => {
    return {
      progress: false,
      token: ref(''),
      id: ref(0),
      username: ref(''),
      fullname: ref(''),
      shopname: ref(''),
      response: null,
      accounttypeid: ref(0),
    };
  }, //defaultState,
  getters: {
    isAnonymous(state) {
      return state.token ? false : true;
    },
    userId(state) {
      return state.id;
    },
    name(state) {
      return state.name;
    },
    returnRedirect(state) {
      return state.returnUrl;
    },
    inProgress(state) {
      return state.progress;
    },
    accountTypeId(state) {
      return state.accounttypeid;
    },
    printBaseUrl() {
      if (window.location.hostname == 'localhost') {
        return 'http://localhost:4011';
      } else if (window.location.hostname.includes('develop') || window.location.hostname.includes('pages.dev')) {
        return `https://admin.ibk.cockpitgaming.io`;
      } else {
        return `https://admin.${window.location.hostname}`;
      }
    },
    getToken() {
      return JSON.parse(localStorage.getItem('auth')).Token;
    }
  },
  actions: {
    async login(credentials) {
      const alert = useAlertStore();
      try {
        let authResult = await authenticate(
          credentials.username,
          credentials.password
        );
        
        if (authResult.status == 'success') {
          if (isActiveSite('AGENT')) {
            if (authResult.data.AccountTypeId == 1)
              this.returnUrl = '/registrations';
            else if (authResult.data.AccountTypeId == 2) 
              this.returnUrl = '/revenue/update/subagent';
            else 
              this.returnUrl = '/revenue/update/cashier';
            authResult.data.UserId = authResult.data.AccountId;
            authResult.data.username = authResult.data.Name;
          } else if (isActiveSite('COLLECTOR')) {
            console.log('collector for presentation');
            this.returnUrl = '/assigned/betting/shop';
            authResult.data.UserId = authResult.data.AccountId;
            authResult.data.username = authResult.data.Name;
          } else {
            let accountInfo = await getAccountInfo(credentials.username);
            authResult.data.UserId = accountInfo.data[0].UserId;
            authResult.data.username = accountInfo.data[0].Username;
          }
          localStorage.setItem('auth', JSON.stringify(authResult.data));
          this.setLoggedIn(JSON.parse(localStorage.getItem('auth')));
          alert.successCode('loginSuccess');
          setTimeout(() => {
            router.push(this.returnUrl ?? '/place/bet');
          }, 500);
          return true;
        } else {
          alert.errorCode('error.login.' + authResult.data['ErrorCode']);
          /*          sessionStorage.setItem(
            'ccode',
            'error.login.' + authResult.data['ErrorCode']
          );
		  */
          // throw "error.login." + authResult.data["ErrorCode"];
        }
      } catch (e) {
        this.$reset();
        this.$patch((state) => {
          state.errors = e;
          state.token = '';
          state.user = {};
        });
        alert.errorCode(e ?? 'You are not authorized');
        return false;
      }
    },
    async logout() {
      // console.log(this.userId); // this.userId turns to 0 when reload. so it will not logout. on if (this.userId);
      if (localStorage.getItem('auth')) { 
        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        await logout(this.userId);
        // console.log("logout", authLogout);
        this.$patch((state) => {
          state.token = '';
          state.userId = 0;
          state.fullname = '';
          state.shopname = '';
        });
        delete defaults.headers['x-access-token'];
      }
      router.push('/login');
    },
    done() {
      this.$patch((state) => {
        state.progress = false;
      });
    },
    setLoggedIn(data, redirect=true) {
      this.$patch((state) => {
        state.token = data.Token;
        state.id = data.UserId;
        state.accounttypeid = data.AccountTypeId;
        state.username = data.username;
        state.fullname =
          isActiveSite('AGENT') || isActiveSite('COLLECTOR')
            ? data.Name
            : [data.FirstName, data.LastName].join(' ');
        state.shopname = data.BettingShopName;
      });

      defaults.headers['x-access-token'] = data.Token;

      setTimeout(() => {
        realtimeEvents.listenToUserEvents(data);
      }, 1000);

      //login redirection
      if (redirect) {
        if (isActiveSite('AGENT')) {
          router.push({ name: 'RegisTrations' });
        } else if (isActiveSite('COLLECTOR')) {
          router.push({ name: 'AssignedBetttingShop' });
        } else {
          router.push({ name: 'PlaceBet' });
        }
      }
      
    },
    verifyLocalStorage() {
      let authData = JSON.parse(localStorage.getItem('auth') ?? '{}');
      if (authData.Token != undefined) {
        this.setLoggedIn(authData);
      }
    },
    reset() {
      // console.log("reset");
    },
    subscribe() {},
    validateEmail(email) {
      console.log(email);
      //eslint-disable-next-line
      let format = /^[\-0-9a-zA-Z\.\+_]+@[\-0-9a-zA-Z\.\+_]+\.[a-zA-Z]{2,}$/;
      if (format.test(email)) {
        return true;
      }
      return false;
    },
  },
});
