<template>
  <table class="table table-striped table-hover dtable-border" width="100%">
    <thead>
      <tr>
        <th>{{ $t("date") }}</th>
        <th>{{ $t("create.account-type") }}</th>
        <th>{{ $t("create.name") }}</th>
        <th>{{ $t("label.BettingShop") }}</th>
        <th>{{ $t("label.Event") }}</th>
        <th class="text-right">
          {{ $t("label.TotalBetAmount") }}
        </th>
        <th class="text-right">{{ $t("label.EventRake") }}</th>
        <th class="text-right">GGR</th>
        <th class="text-right">{{ $t("label.RevShare") }}</th>
        <th class="text-center">{{ $t("amount") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr :key="index" v-for="(item, index) in dataList">
        <td>{{ item.date }}</td>
        <td>{{ item.accountType }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.bettingShop }}</td>
        <td>{{ item.event }}</td>
        <td class="text-right">{{ item.totalBet }}</td>
        <td class="text-right">{{ item.eventRake }}</td>
        <td class="text-right">{{ item.ggr }}</td>
        <td class="text-right">{{ item.revShare }}</td>
        <td class="text-center">{{ item.amount }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "ContentToDownload",
  props: ["dataList"],
};
</script>