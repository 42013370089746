<template>
  <div class="row aligns-items-center justify-content-center">
    <div class="col-12 col-md-12 col-lg-12 col-xl-12 center-holder-box pe-4">
      <div class="lang-switcher-outer hide-elem">
        <div class="lang-switcher-holder">
          <LocaleSwitcher />
        </div>
      </div>
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("summaryreports") }}</div>
          </div>
          <div class="tab-content">
            <div class="row">
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-3">
                    <label class="form-label"
                      >{{ $t("label.FilterBy") }}:</label
                    >
                    <Select2
                      v-model="filterVal"
                      :options="filterByOption"
                      :settings="{ minimumResultsForSearch: -1 }"
                      @select="selectFilter($event)"
                    />
                  </div>
                  <div class="col-12 col-sm-9">
                    <div class="row" v-if="filterVal == 2">
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateFrom") }}:</label
                        >
                        <input
                          type="date"
                          v-model="dateFrom"
                          class="form-control agent-text"
                        />
                      </div>
                      <div class="col-12 col-sm-6">
                        <label class="form-label"
                          >{{ $t("label.DateTo") }}:</label
                        >
                        <input
                          type="date"
                          v-model="dateTo"
                          class="form-control agent-text"
                        />
                      </div>
                    </div>
                    <div class="row" v-else>
                      <div class="col-12">
                        <label class="form-label"
                          >{{ $t("label.Event") }}:</label
                        >
                        <Select2
                          v-model="eventValue"
                          :options="eventOptions"
                          :settings="{
                            settingOption: value,
                            settingOption: value,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 hide-elem">
                <div class="row">
                  <div class="col-12 col-sm-6">
                    <label class="form-label"
                      >{{ $t("create.account-type") }}:</label
                    >
                    <Select2
                      v-model="AccountTypeValue"
                      :options="AccountTypeOptions"
                      :settings="{ minimumResultsForSearch: -1 }"
                    />
                  </div>
                  <div class="col-12 col-sm-6">
                    <label class="form-label">{{ $t("create.name") }}:</label>
                    <Select2
                      v-model="NameValue"
                      :options="NameOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 hide-elem">
                <div class="row">
                  <div class="col-3">
                    <label class="form-label"
                      >{{ $t("label.BettingShop") }}:</label
                    >
                    <Select2
                      v-model="BettingShopValue"
                      :options="BettingShopOptions"
                      :settings="{ settingOption: value, settingOption: value }"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3 mb-5 hide-elem">
                <div class="row">
                  <div class="col-6"></div>
                  <div class="col-6 px-4">
                    <div class="row">
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-gen"
                          :disabled="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? false
                              : true
                          "
                          @click="generateData"
                          :class="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? 'active'
                              : null
                          "
                        >
                          {{ $t("button.generatereport") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-dl"
                          @click="generatePDF"
                          :disabled="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? false
                              : true
                          "
                          :class="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? 'active'
                              : null
                          "
                        >
                          {{ $t("button.download") }}
                        </button>
                      </div>
                      <div class="col-4 px-1">
                        <button
                          type="button"
                          class="rs-btn rs-print"
                          @click="printPreview"
                          :disabled="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? false
                              : true
                          "
                          :class="
                            (dateFrom && dateTo) || filterVal !== 2
                              ? 'active'
                              : null
                          "
                        >
                          {{ $t("button.reprintreport") }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <vue3-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="true"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                filename="Summary Reports"
                :pdf-quality="2"
                :manual-pagination="false"
                :pdf-margin="20"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                @progress="onProgress($event)"
                @hasStartedGeneration="hasStartedGeneration()"
                @hasGenerated="hasGenerated($event)"
                ref="html2Pdf"
              >
                <template v-slot:pdf-content>
                  <ContentToDownload :dataList="dataList2" />
                </template>
              </vue3-html2pdf>
              <div class="col-12 col-sm-12 mb-3" id="printTable">
                <div class="position-relative">
                  <table
                    class="table table-striped table-hover dtable-border"
                    width="100%"
                  >
                    <thead>
                      <tr>
                        <th>{{ $t("date") }}</th>
                        <th>{{ $t("create.account-type") }}</th>
                        <th>Name</th>
                        <th>{{ $t("label.BettingShop") }}</th>
                        <th>{{ $t("label.Event") }}</th>
                        <th class="text-right">
                          {{ $t("label.TotalBetAmount") }}
                        </th>
                        <th class="text-right">{{ $t("label.EventRake") }}</th>
                        <th class="text-right">GGR</th>
                        <th class="text-right">{{ $t("label.RevShare") }}</th>
                        <th class="text-center">{{ $t("amount") }}</th>
                      </tr>
                    </thead>
                    <tbody v-if="dataList.length">
                      <tr :key="index" v-for="(item, index) in dataList">
                        <td>{{ item.date }}</td>
                        <td>{{ item.accountType }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.bettingShop }}</td>
                        <td>{{ item.event }}</td>
                        <td class="text-right">{{ item.totalBet }}</td>
                        <td class="text-right">{{ item.eventRake }}</td>
                        <td class="text-right">{{ item.ggr }}</td>
                        <td class="text-right">{{ item.revShare }}</td>
                        <td class="text-center">{{ item.amount }}</td>
                      </tr>
                    </tbody>
                    <tr v-else>
                      <td colspan="10" class="text-center pt-3">
                        No records found.
                      </td>
                    </tr>
                  </table>
                  <div v-if="loading" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          v-if="dataList.length"
          :AccountId="AccountId"
          :pagenum="pagenum"
          :page="page"
          :filterVal="filterVal"
          :dateFrom="dateFrom"
          :dateTo="dateTo"
          :eventValue="eventValue"
          :AccountTypeValue="AccountTypeValue"
          :NameValue="NameValue"
          :BettingShopValue="BettingShopValue"
          :dataTotal="dataTotal"
          @triggerPaginate="paginate"
          ref="trigger"
        />
      </div>
    </div>
  </div>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import Vue3Html2pdf from "vue3-html2pdf";
import ContentToDownload from "../components/ContentToDownload.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import Select2 from "vue3-select2-component";
import Pagination from "../components/Pagination";
import {
  getAgentSummaryReport,
  getAgentEventList,
  getAgentNameList,
  getAgentBettingshopList,
  getAgentSummaryReportTotal,
} from "../api";
export default {
  name: "ReportSummary",
  components: {
    Pagination,
    Select2,
    LocaleSwitcher,
    Vue3Html2pdf,
    ContentToDownload,
  },
  data() {
    return {
      page: "reportSummary",
      eventValue: 0,
      AccountTypeValue: 0,
      NameValue: 0,
      BettingShopValue: 0,
      dateFrom: "",
      dateTo: "",
      AccountTypeOptions: [
        { id: 2, text: "Agent" },
        { id: 3, text: "Sub Agent" },
        { id: 4, text: "Cashier" },
      ],
      NameOptions: [],
      BettingShopOptions: [],
      eventOptions: [],
      filterByOption: [
        { id: 2, text: "Date" },
        { id: 1, text: "Event" },
      ],
      filterVal: 2,
      loading: false,
      AccountId: 0,
      pagenum: 1,
      successMessage: "",
      errorMessage: "",
      disabled: true,
      dataList: [
        {
          date: "06/30/2021",
          accountType: "Master Agent",
          name: "Name 1",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "1,500,000.00",
          eventRake: "10",
          ggr: "150,000.00",
          revShare: "2",
          amount: "30,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Agent",
          name: "Name 2",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "500,000.00",
          eventRake: "10",
          ggr: "50,000.00",
          revShare: "2",
          amount: "5,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Agent",
          name: "Name 3",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "1,000,000.00",
          eventRake: "10",
          ggr: "100,000.00",
          revShare: "1",
          amount: "10,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Sub Agent",
          name: "Name 4",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "500,000.00",
          eventRake: "10",
          ggr: "50,000.00",
          revShare: "1",
          amount: "5,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Sub Agent",
          name: "Name 5",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "1,000,000.00",
          eventRake: "10",
          ggr: "100,000.00",
          revShare: "1",
          amount: "10,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier",
          name: "Name 6",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "200,000.00",
          eventRake: "10",
          ggr: "20,000.00",
          revShare: "3",
          amount: "6,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier",
          name: "Name 7",
          bettingShop: "Shop 1",
          event: "Event 1",
          totalBet: "300,000.00",
          eventRake: "10",
          ggr: "30,000.00",
          revShare: "3",
          amount: "9,000.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier",
          name: "Name 8",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "450,000.00",
          eventRake: "10",
          ggr: "45,000.00",
          revShare: "3",
          amount: "13,500.00",
        },
        {
          date: "06/30/2021",
          accountType: "Cashier",
          name: "Name 9",
          bettingShop: "Shop 2",
          event: "Event 1",
          totalBet: "550,000.00",
          eventRake: "10",
          ggr: "55,000.00",
          revShare: "3",
          amount: "16,500.00",
        },
      ],
      dataList2: [],
      dataTotal: 0,
    };
  },
  methods: {
    hasGenerated() {
      this.successMessage = "PDF generated successfully!";
      this.$refs.modalSuccess.open();
      setTimeout(() => this.$refs.modalSuccess.close(), 5000);
    },
    generatePDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    printPreview() {
      document.getElementById("printTable").innerHTML;
      window.focus();
      window.print();
    },
    selectFilter($event) {
      if ($event.id == 1) {
        this.dateFrom = "";
        this.dateTo = "";
      }
    },
    async fetchSummaryReports($params) {
      let res = await getAgentSummaryReport($params);
      if (res.status == "success") {
        this.dataList = res.data[0].map((item) => {
          return {
            date: new Date(item.EventDate).toLocaleDateString(),
            accountType: item.AccountType,
            name: item.NAME,
            bettingShop: item.BettingShopName,
            event: item.EventName,
            totalBet:
              Number(item.TotalBetAmount)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
            eventRake: item.EventRake,
            ggr:
              Number(item.GGR)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
            revShare: item.RevShare,
            amount:
              Number(item.RevShareAmount)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
          };
        });
      } else {
        this.errorMessage = "Agent List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async fetchSummaryReportsTotal($params) {
      let res = await getAgentSummaryReport($params);
      if (res.status == "success") {
        this.dataList2 = res.data[0].map((item) => {
          return {
            date: new Date(item.EventDate).toLocaleDateString(),
            accountType: item.AccountType,
            name: item.NAME,
            bettingShop: item.BettingShopName,
            event: item.EventName,
            totalBet:
              Number(item.TotalBetAmount)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
            eventRake: item.EventRake,
            ggr:
              Number(item.GGR)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
            revShare: item.RevShare,
            amount:
              Number(item.RevShareAmount)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0,
          };
        });
      } else {
        this.errorMessage = "Agent List to download failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async getSummaryReportsTotal($params) {
      let res = await getAgentSummaryReportTotal($params);
      if (res.status == "success") {
        this.dataTotal = res.data.TotalRecords;
      } else {
        this.errorMessage = "Summary Reports Total List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async fetchEventList() {
      let res = await getAgentEventList();
      if (res.status == "success") {
        this.eventOptions = res.data.map((item) => {
          return {
            id: item.eventid,
            text: item.name,
          };
        });
      } else {
        this.errorMessage = "Event List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async fetchNameList() {
      let res = await getAgentNameList();
      if (res.status == "success") {
        this.NameOptions = res.data.map((item) => {
          return {
            id: item.accountid,
            text: item.name,
          };
        });
      } else {
        this.errorMessage = "Name List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    async fetchBettingshopList() {
      let res = await getAgentBettingshopList();
      if (res.status == "success") {
        this.BettingShopOptions = res.data.map((item) => {
          return {
            id: item.bettingshopid,
            text: item.name,
          };
        });
      } else {
        this.errorMessage = "Betting Shop List failed to fetch!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
      this.loading = false;
    },
    paginate(val) {
      if (val === "prev") {
        if (this.pagenum > 1) {
          this.pagenum = parseInt(this.pagenum - 1);
          this.auth = JSON.parse(localStorage.getItem("auth"));
          this.fetchSummaryReports({
            intLoginAgentAccountId: this.auth.AccountId,
            intFilterBy: this.filterVal,
            strDateFrom: this.dateFrom,
            strDateTo: this.dateTo,
            intEventId: this.eventValue,
            intAccountTypeId: this.AccountTypeValue,
            intAgentAccountId: this.NameValue,
            intBettingShopId: this.BettingShopValue,
            intLimitPerPage: 10,
            intStart: parseInt(this.pagenum * 10 - 10),
          });
        }
      } else if (val === "next") {
        if (this.pagenum !== JSON.parse(localStorage.getItem("pageTotal"))) {
          this.pagenum = parseInt(this.pagenum + 1);
          this.auth = JSON.parse(localStorage.getItem("auth"));
          this.fetchSummaryReports({
            intLoginAgentAccountId: this.auth.AccountId,
            intFilterBy: this.filterVal,
            strDateFrom: this.dateFrom,
            strDateTo: this.dateTo,
            intEventId: this.eventValue,
            intAccountTypeId: this.AccountTypeValue,
            intAgentAccountId: this.NameValue,
            intBettingShopId: this.BettingShopValue,
            intLimitPerPage: 10,
            intStart: parseInt(this.pagenum * 10 - 10),
          });
        }
      } else {
        this.pagenum = val;
        this.auth = JSON.parse(localStorage.getItem("auth"));
        this.fetchSummaryReports({
          intLoginAgentAccountId: this.auth.AccountId,
          intFilterBy: this.filterVal,
          strDateFrom: this.dateFrom,
          strDateTo: this.dateTo,
          intEventId: this.eventValue,
          intAccountTypeId: this.AccountTypeValue,
          intAgentAccountId: this.NameValue,
          intBettingShopId: this.BettingShopValue,
          intLimitPerPage: 10,
          intStart: parseInt(this.pagenum * 10 - 10),
        });
      }
    },

    generateData() {
      this.loading = true;
      this.auth = JSON.parse(localStorage.getItem("auth"));

      this.getSummaryReportsTotal({
        intLoginAgentAccountId: this.auth.AccountId,
        intFilterBy: this.filterVal,
        strDateFrom: this.dateFrom,
        strDateTo: this.dateTo,
        intEventId: this.eventValue,
        intAccountTypeId: this.AccountTypeValue,
        intAgentAccountId: this.NameValue,
        intBettingShopId: this.BettingShopValue,
      });
      setTimeout(() => {
        this.fetchSummaryReportsTotal({
          intLoginAgentAccountId: this.auth.AccountId,
          intFilterBy: this.filterVal,
          strDateFrom: this.dateFrom,
          strDateTo: this.dateTo,
          intEventId: this.eventValue,
          intAccountTypeId: this.AccountTypeValue,
          intAgentAccountId: this.NameValue,
          intBettingShopId: this.BettingShopValue,
          intLimitPerPage: this.dataTotal,
          intStart: 0,
        });

        this.fetchSummaryReports({
          intLoginAgentAccountId: this.auth.AccountId,
          intFilterBy: this.filterVal,
          strDateFrom: this.dateFrom,
          strDateTo: this.dateTo,
          intEventId: this.eventValue,
          intAccountTypeId: this.AccountTypeValue,
          intAgentAccountId: this.NameValue,
          intBettingShopId: this.BettingShopValue,
          intLimitPerPage: 10,
          intStart: 0,
        });

        this.$refs.trigger.reloadPaginate();
        this.pagenum = 1;
      }, 1000);
    },
  },

  mounted() {
    this.auth = JSON.parse(localStorage.getItem("auth"));
    this.AccountId = this.auth.AccountId;
    this.dataList = [];
    this.loading = true;
    this.fetchEventList();
    this.fetchNameList();
    this.fetchBettingshopList();
  },
};
</script>

<style scoped>
@import "bootstrap";
@import "datatables.net-bs5";
@media print {
  .hide-elem,
  .sidebar-holder-outer {
    display: none;
  }
  .tabs-holder-outer {
    display: block;
    width: 1160px;
    margin-left: 30px;
  }
  #printTable {
    display: block;
    width: 100%;
    margin: 0 auto;
    float: none;
  }
  /* @page {
    size: a4 landscape;
  } */
}
table.dataTable thead > tr > th.sorting:before,
table.dataTable thead > tr > th.sorting:after {
  display: none !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination {
  display: flex;
  margin: 2px 0 !important;
  margin-top: 15px !important;
}
div.dataTables_wrapper div.dataTables_paginate ul.pagination li {
  padding: 0;
  text-decoration: none;
  background: none;
  border-radius: 0;
  font-weight: normal;
}
div.dataTables_wrapper div.row div.col-md-6:first-child {
  display: none;
}
.dataTables_filter label {
  display: block;
}

.dtable-input {
  width: 89px;
  height: 35px;
  border: 1px solid #c9c9c9;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
}

.dtable-input2 {
  width: 89px;
  height: 35px;
  border: 1px solid #707070;
  border-radius: 6px;
  text-align: right;
  padding: 0 8px;
  font-size: 16px;
  display: inline-block;
}
.dtable-btn {
  width: 68px;
  height: 35px;
  background: #23ad0e;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  top: -1px;
}
.dtable-btn2 {
  width: 116px;
  height: 35px;
  background: #35469a;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
}
.top-input {
  width: 122px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
  text-align: right;
  display: inline-block;
}
.top-apply-btn {
  width: 107px;
  height: 40px;
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2559;
  border-radius: 6px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
}
.ds-block {
  display: block;
}
.update-share-holder {
  display: inline-block;
  text-align: left;
}
.top-search {
  width: 241px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #707070;
  border-radius: 5px;
}
table.dataTable td {
  vertical-align: middle;
}
table.dataTable th {
  vertical-align: top;
}
.dtable-text:focus {
  box-shadow: none;
  border: 1px solid #707070;
}
.tab-content {
  padding-bottom: 2% !important;
  padding-top: 2% !important;
}
.page-link.active,
.active > .page-link {
  background-color: var(--violet);
  border-color: var(--violet);
}
.rs-btn {
  width: 100%;
  font-size: 16px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  color: #656565;
  background: #efefef;
  box-shadow: none;
  border: none;
}
.rs-gen.active {
  background: #e84e25 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #e84e2570;
  color: #fff;
}
.rs-dl.active {
  background: #0081ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0081ff59;
  color: #fff;
}
.rs-print.active {
  background: #2cb742 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2cb74259;
  color: #fff;
}
.dtable-border {
  border-top: 1px solid #efefef;
}
.table th,
.table td {
  vertical-align: middle;
}
</style>