<template>
  <div class="row">
    <div class="col-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 center-holder-box">
      <div class="tabs-holder-outer">
        <div class="tabs-holder">
          <div class="tabs-holder-inner">
            <div class="tabs-title">{{ $t("sidebar.Payout") }}</div>
          </div>
          <div class="tab-content">
            <div class="container bet-cancel-data">
              <div class="row transaction-sect align-items-center">
                <div class="col-md-3 col-xl-2">
                  <p class="text-left m-0">{{ $t("transactionno") }}:</p>
                </div>
                <div class="col-9 col-sm-8 col-md-7 col-xl-8">
                  <input
                    type="text"
                    class="cash-text card-text"
                    placeholder="XXX-XXXXXX"
                    ref="payOutInput"
                    :value="transNo"
                    @keyup="inputOnlyNumber"
                  />
                </div>
                <div class="col-3 col-sm-4 col-md-2 col-xl-2 col-xxl-2 p-0">
                  <button type="button" class="clear-btn2" @click="clearInput">
                    {{ $t("button.clear") }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="container bet-cancel-data"
              v-bind:key="index"
              v-for="(data, index) in dataPayout"
            >
              <div class="row grid-holder">
                <div class="col-6">
                  <p class="text-left">{{ $t(data.title) }}:</p>
                </div>
                <div class="col-6">
                  <p class="text-right font-bold">{{ data.value }}</p>
                </div>
              </div>
            </div>
            <BetFooter
              :disabled="disabled"
              :status="status"
              @handleActionPayOut="payOut"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 right-nav-hider"
      :class="{
        'rightbar--open': open2 === true,
      }"
    >
      <div class="text-left">
        <img
          src="../assets/img/close_box.svg"
          class="close-slider"
          @click="closeBox"
        />
      </div>
      <RightNav @onClick="showModal" />
    </div>
  </div>
  <Modal
    :status="status"
    :status2="status2"
    v-if="isShowModal"
    @close="close"
    v-model:threshold="threshold"
    @submitThresholdAction="submitThresholdActions"
  >
  </Modal>
  <sweet-modal ref="modalError" icon="error">
    {{ errorMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
  <sweet-modal ref="modalSuccess" icon="success">
    {{ successMessage }}
    <div class="text-center">
      <button type="button" class="sweet-btn" @click="closeModal">
        {{ $t("button.close") }}
      </button>
    </div>
  </sweet-modal>
</template>

<script>
import BetFooter from "../components/tabs/BetFooter";
import RightNav from "../components/RightNav";
import Modal from "../components/modal/InputModal";
import { usePayoutStore } from "@/store/payout.store";
import { useAuthStore } from "@/store/user.store";
import { useThresholdStore } from "@/store/threshold.store";
import { useLicenseStatus } from "@/store/license.store";
import { useStats } from "@/store/stats.store";
// import router from "../router"; // Commenting for Revised Print

export default {
  name: "PayOut",
  props: ["open2"],
  emits: ["closeBoxz"],
  components: { BetFooter, RightNav, Modal },
  data() {
    return {
      status: "PayOut",
      status2: false,
      isShowModal: false,
      transNo: "",
      disabled: true,
      successMessage: "",
      errorMessage: "",
      threshold: null,
      intBetId: "",
      strPinCode: "",
      dataPayout: [
        {
          id: 1,
          title: "transactionno",
          value: "",
        },
        {
          id: 2,
          title: "date-and-time",
          value: "",
        },
        {
          id: 3,
          title: "fightno",
          value: "",
        },
        {
          id: 4,
          title: "betoption",
          value: "",
        },
        {
          id: 5,
          title: "betamount",
          value: "",
        },
        {
          id: 6,
          title: "sidebar.Payout",
          value: "",
        },
        {
          id: 7,
          title: "odds",
          value: "",
        },
        {
          id: 8,
          title: "cashiername",
          value: "",
        },
        {
          id: 9,
          title: "status",
          value: "",
        },
      ],
    };
  },
  methods: {
    closeBox() {
      this.$emit("closeBoxz");
    },
    inputOnlyNumber($event) {
      // let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      //   // 46 is dot
      //   $event.preventDefault();
      // }

      this.transNo = $event.target.value;
      let mArr = this.transNo.split("-");
      // console.log(mArr);
      if (mArr[0].length >= 1 && mArr[1] && mArr[1].length >= 6) {
        this.handleGetPayout();
      }
    },
    close() {
      this.isShowModal = false;
      this.status2 = false;
    },
    closeModal() {
      this.$refs.modalSuccess.close();
      this.$refs.modalError.close();
    },
    showModal() {
      this.isShowModal = true;
      this.status2 = true;
      this.threshold = null;
    },
    clearInput() {
      this.transNo = "";
      this.disabled = true;
      this.dataPayout[0].value = "";
      this.dataPayout[1].value = "";
      this.dataPayout[2].value = "";
      this.dataPayout[3].value = "";
      this.dataPayout[4].value = "";
      this.dataPayout[5].value = "";
      this.dataPayout[6].value = "";
      this.dataPayout[7].value = "";
      this.dataPayout[8].value = "";
    },
    handleGetPayout() {
      const str = this.transNo.split("-");
      let text1 = str[0];
      let text2 = str[1] || "";
      const param = "?intBetId=" + text1 + "&strPinCode=" + text2;
      const action = usePayoutStore();
      if (text2.length < 1) return;
      action.getPayout(param);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        if (result.status === "success") {
          this.disabled = false;
          this.dataPayout[0].value = result.data.TransactionNo;
          let $datetime = new Date(result.data.TransactionDateTime);
          this.dataPayout[1].value =
            $datetime.toLocaleDateString() +
            " " +
            $datetime.toLocaleTimeString();
          this.dataPayout[2].value = result.data.FightNumber;
          this.dataPayout[3].value = result.data.BetOption;
          this.dataPayout[4].value =
            Number(result.data.BetAmount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
          this.dataPayout[5].value =
            Number(result.data.Payout)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
          this.dataPayout[6].value =
            Number(result.data.Odds)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
          this.dataPayout[7].value = result.data.CashierName;
          this.dataPayout[8].value = result.data.Status;
          this.setReciept(result.data);
        } else {
          this.disabled = true;
          this.dataPayout[0].value = "";
          this.dataPayout[1].value = "";
          this.dataPayout[2].value = "";
          this.dataPayout[3].value = "";
          this.dataPayout[4].value = "";
          this.dataPayout[5].value = "";
          this.dataPayout[6].value = "";
          this.dataPayout[7].value = "";
          this.dataPayout[8].value = "";

          if (result.data.ErrorCode == 10000070) {
            localStorage.removeItem("auth");
            window.location.reload();
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        }
      });
    },

    setReciept(data) {
      localStorage.removeItem("module");
      localStorage.removeItem("TransactionReceipt");
      localStorage.removeItem("transactionno");
      localStorage.removeItem("datetime");
      localStorage.removeItem("fightno");
      localStorage.removeItem("betoption");
      localStorage.removeItem("betamount");
      localStorage.removeItem("payout");
      localStorage.removeItem("odds");
      localStorage.removeItem("cashierno");
      localStorage.removeItem("statuss");
      localStorage.removeItem("barcode");

      localStorage.removeItem("availablecredits");
      localStorage.removeItem("cashin");
      localStorage.removeItem("cashonhand");
      localStorage.removeItem("payouts");
      localStorage.removeItem("approvedcredit");
      localStorage.removeItem("cashiername");
      localStorage.removeItem("timestamp");

      localStorage.setItem("module", JSON.stringify("PayOut"));
      localStorage.setItem("transactionno", JSON.stringify(data.TransactionNo));
      localStorage.setItem(
        "datetime",
        JSON.stringify(data.TransactionDateTime)
      );
      localStorage.setItem("fightno", JSON.stringify(data.FightNumber));
      localStorage.setItem("betoption", JSON.stringify(data.BetOption));
      localStorage.setItem(
        "betamount",
        JSON.stringify(
          Number(data.BetAmount)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "payout",
        JSON.stringify(
          Number(data.Payout)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem(
        "odds",
        JSON.stringify(
          Number(data.Odds)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0
        )
      );
      localStorage.setItem("cashierno", JSON.stringify(data.CashierNo));
      localStorage.setItem("statuss", JSON.stringify(data.Status));
    },
    payOut() {
      const str = this.transNo.split("-");
      let text1 = str[0];
      let text2 = str[1];
      const action = usePayoutStore();
      const param = {
        intBetId: text1,
        strPinCode: text2,
      };
      action.submitPayout(param);
      const license = useLicenseStatus();
      license.getLicense();
      action.response.then((result) => {
        if (result.status === "success") {
          this.disabled = true;
          this.successMessage = this.$t("payout-submit-successfully");
          this.$refs.modalSuccess.open();
          setTimeout(() => this.$refs.modalSuccess.close(), 5000);
          // setTimeout(() => router.push({ name: "ReCeipt" }), 6000); // OLD RECEIPT
          // Revised Print
            const awew = useAuthStore();
            let $betId = this.transNo.split("-")[0];
            let printEndpoint = `${awew.printBaseUrl}/cashier/print/receipt/payout/${$betId}/${awew.getToken}`;
            window.open(printEndpoint);
            
          const stats = useStats();
          stats.refresh();
        } else {
          this.disabled = true;
          if (result.data.ErrorCode == 10000070) {
            localStorage.removeItem("auth");
            window.location.reload();
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = this.$t("error." + result.data.ErrorCode);
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        }
      });
    },
    submitThresholdActions() {
      if (this.threshold > 0) {
        const action = useThresholdStore();
        action.submitThresholdAmount(this.threshold);
        const license = useLicenseStatus();
        license.getLicense();
        action.response.then((result) => {
          // console.log(result);
          if (result.status === "success") {
            this.successMessage = "Your Request Submitted Successfully!";
            this.$refs.modalSuccess.open();
            setTimeout(() => this.$refs.modalSuccess.close(), 5000);
            this.isShowModal = false;
          } else {
            if (result.data.ErrorCode == "10000092") {
              this.errorMessage = this.$t(
                "error.login." + result.data.ErrorCode
              );
              var auth = useAuthStore();
              setTimeout(() => auth.logout(), 3000);
            } else {
              this.errorMessage = "Your Request Failed!";
            }
            this.$refs.modalError.open();
            setTimeout(() => this.$refs.modalError.close(), 5000);
          }
        });
      } else {
        this.errorMessage = "Please make sure to enter amount!";
        this.$refs.modalError.open();
        setTimeout(() => this.$refs.modalError.close(), 5000);
      }
    },
  },
  mounted() {
    this.$refs.payOutInput.focus();
    const license = useLicenseStatus();
    license.getLicense();
    const stats = useStats();
    stats.refresh();
  },
};
</script>
