<template>
  <!-- <div class="error-code">{{ $t("errorCode.100070") }}</div> -->
  <ul class="sidebar-nav" v-if="activeSite == 'CASHIER'">
    <router-link :to="{ name: 'PlaceBet' }"
      ><li>{{ $t("sidebar.PlaceBet") }}</li></router-link
    >
    <router-link :to="{ name: 'PayOut' }"
      ><li>{{ $t("sidebar.Payout") }}</li></router-link
    >
    <router-link :to="{ name: 'TransactionLog' }"
      ><li>{{ $t("sidebar.TransactionLogs") }}</li></router-link
    >
    <router-link :to="{ name: 'CancelBet' }"
      ><li>{{ $t("sidebar.CancelBet") }}</li></router-link
    >
    <router-link :to="{ name: 'CreditRequest' }"
      ><li>{{ $t("sidebar.RequestCredit") }}</li></router-link
    >
    <router-link :to="{ name: 'SummaryReports' }"
      ><li>{{ $t("sidebar.SummaryReports") }}</li></router-link
    >
    <router-link :to="{ name: 'CashEndorsement' }"
      ><li>{{ $t("sidebar.CashEndorsement") }}</li></router-link
    >
    <router-link :to="{ name: 'RemittanceHistory' }"
      ><li>{{ $t("sidebar.RemittanceHistory") }}</li></router-link
    >
  </ul>

  <ul class="sidebar-nav" v-if="activeSite == 'AGENT'">
  <div v-if="this.AccountTypeId==1">
    <li @click="openSlide">
      {{ $t("sidebar.Agents") }}
      <i class="fas fa-caret-down float-end sidebar-arrow"></i>
    </li>
    <ul class="sidebar-nav-sub" :class="{ 'close-slide': slide === false }">
      <router-link :to="{ name: 'RegisTrations' }"
        ><li v-if="this.AccountTypeId==1">{{ $t("sidebar.Registrations") }}</li></router-link
      >
      <router-link v-if="this.AccountTypeId==1" :to="{ name: 'RevenueUpdateAgent' }"
        ><li>{{ $t("sidebar.RevenueUpdates") }}</li></router-link
      >
    </ul>
  </div>
    <router-link v-if="this.AccountTypeId==2" :to="{ name: 'RevenueUpdateSubAgent' }"
        ><li>{{ $t("sidebar.RevenueUpdates") }}</li></router-link
      >
    <router-link v-if="this.AccountTypeId==3" :to="{ name: 'RevenueUpdateCashier' }"
        ><li>{{ $t("sidebar.RevenueUpdates") }}</li></router-link
      >
    <router-link :to="{ name: 'ReportSummary' }"
      ><li>{{ $t("sidebar.SummaryReports") }}</li></router-link
    >
    <router-link :to="{ name: 'Commissions' }"
      ><li v-if="this.AccountTypeId==1">{{ $t("label.Commissions") }}</li></router-link
    >
    <router-link :to="{ name: 'CollectionReport' }"
      ><li v-if="this.AccountTypeId==1">{{ $t("label.CollectionReports") }}</li></router-link
    >
    >
  </ul>
  <ul class="sidebar-nav" v-if="activeSite == 'COLLECTOR'">
    <router-link :to="{ name: 'AssignedBetttingShop' }"
      ><li>{{ $t("label.AssignedBettingShop") }}</li></router-link
    >
    <!-- <router-link :to="{ name: 'CReportSummary' }"
      ><li>{{ $t("sidebar.SummaryReports") }}</li></router-link
    > -->
    <router-link :to="{ name: 'CollectionReport' }"><li>Collection Reports</li></router-link>
  </ul>
</template>

<script>
import { useAuthStore } from "@/store/user.store";

export default {
  name: "SideBarNav",
  data() {
    return {
      slide: true,
      AccountTypeId: null
    };
  },
  methods: {
    openSlide() {
      this.slide = !this.slide;
    },
  },
  mounted(){
    
    const auth = useAuthStore();
    this.AccountTypeId = auth.accountTypeId;
  
  }
  
};
</script>